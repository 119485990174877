import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getListById } from "../api/list";

const ListDetail = () => {
  const { id } = useParams();
  const [listData, setListData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const data = await getListById(id);
        setListData(data);
      } catch (error) {
        console.error("Error fetching list data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchListData();
  }, [id]);

  if (loading) return <div>Loading...</div>;

  if (error) {
    return (
      <div style={styles.container}>
        <div style={styles.overlay}></div>
        <div style={styles.content}>
          <h1 style={styles.title}>List not found!</h1>
          <p style={styles.message}>Please check your URL and try again</p>
          {/* <a href="/" style={styles.downloadButton}>
            Download Bijou
          </a> */}
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <style>{`
        .custom-scrollbar {
          scrollbar-width: none; /* For Firefox */
          -ms-overflow-style: none;  /* For Internet Explorer and Edge */
        }
        .custom-scrollbar::-webkit-scrollbar {
          width: 0; /* For Chrome, Safari, and Opera */
        }
      `}</style>
      <div style={styles.overlay}></div>
      <div style={styles.header}>
        <h1 style={styles.title}>{listData.listName}</h1>
        <p style={styles.madeWithLove}>
          Made with 💕 by{" "}
          <Link to={`/user/${listData.username}`} style={styles.userLink}>
            {listData.username}
          </Link>
        </p>
      </div>
      <div style={styles.content} className="custom-scrollbar">
        <div style={styles.listContainer}>
          {listData.items.map((item) => (
            <div key={item.id} style={styles.listItem}>
              <img
                src={`https://image.tmdb.org/t/p/w200${item.poster_path}`}
                alt={item.name}
                style={styles.listItemImage}
              />
              <p style={styles.listItemTitle}>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      <p style={styles.shareMessage}>
        Share your own lists and so much more with Bijou
      </p>
      <p style={styles.platformLinks}>
        <a href="/" style={styles.platformLink}>
          Android
        </a>{" "}
        <span style={styles.separator}>|</span>{" "}
        <a href="/" style={styles.platformLink}>
          iOS
        </a>
      </p>
    </div>
  );
};

const styles = {
  container: {
    minHeight: "100vh",
    backgroundColor: "#070312",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(13, 13, 25, 0.75)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backdropFilter: "blur(5px)",
  },
  header: {
    textAlign: "center",
    zIndex: 2,
    marginBottom: "20px",
  },
  title: {
    color: "#FFF",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: 0,
  },
  madeWithLove: {
    color: "#FFF",
    fontSize: "14px",
    marginBottom: "20px",
    marginTop: 0,
  },
  content: {
    position: "relative",
    zIndex: 2,
    padding: "20px",
    backgroundColor: "#070312",
    borderRadius: "15px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    maxWidth: "400px",
    maxHeight: "600px",
    width: "100%",
    border: "1px solid",
    borderColor: "#D32F53",
    overflowY: "auto",
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "10px",
  },
  listItem: {
    width: "calc(33.333% - 20px)",
    textAlign: "center",
  },
  listItemImage: {
    width: "100%",
    borderRadius: "10px",
  },
  listItemTitle: {
    color: "#FFF",
    fontSize: "12px",
  },
  shareMessage: {
    color: "#FFF",
    fontSize: "14px",
    textAlign: "center",
    marginTop: "20px",
    zIndex: 3,
    marginBottom: 0,
  },
  platformLinks: {
    color: "#FFF",
    fontSize: "14px",
    textAlign: "center",
    marginTop: "10px",
    zIndex: 3,
  },
  platformLink: {
    color: "#D32F53",
    textDecoration: "none",
    fontWeight: "bold",
    marginTop: 0,
  },
  separator: {
    color: "#FFF",
  },
  userLink: {
    color: "#D32F53",
    textDecoration: "none",
    fontWeight: "bold",
  },
};

export default ListDetail;
